import { FeatureToggle } from 'hooks/useFeatureFlags/useFeatureFlags';
import MeetAPIServiceBase from './MeetAPIServiceBase';

export default class MeetAPIServiceV1 extends MeetAPIServiceBase {
  API_VERSION: string = 'v1';
  MEET_BACKEND_URL: string = `${process.env.REACT_APP_MEET_BACKEND_URL}/${this.API_VERSION}`;

  sendEmail = async (data: any) => {
    const URL = `${this.MEET_BACKEND_URL}/rooms/send_invite_link`;
    return this.axiosInstance.post(URL, data);
  };

  getToken = async (meeting_link: string, identity: string, builder_user: boolean) => {
    const params = { meeting_link, identity, builder_user };
    const URL = `${this.MEET_BACKEND_URL}/rooms/access_token`;
    return this.axiosInstance.get(URL, { params });
  };

  getParticipantList = async (room_name: string) => {
    const URL = `${this.MEET_BACKEND_URL}/rooms/participants`;
    return this.axiosInstance.get(URL, { params: { room_name } });
  };

  isValidMeetingLink = async (meeting_link: string) => {
    const URL = `${this.MEET_BACKEND_URL}/rooms/is_valid_room`;
    return this.axiosInstance.get(URL, { params: { meeting_link } });
  };

  isValidMeetingTime = async (meeting_link: string) => {
    const URL = `${this.MEET_BACKEND_URL}/rooms/is_valid_time`;
    return this.axiosInstance.get(URL, { params: { meeting_link } });
  };

  endMeeting = async (roomSid: string) => {
    const URL = `${this.MEET_BACKEND_URL}/rooms/${roomSid}`;
    return this.axiosInstance.delete(URL);
  };

  dial_phone = async (data: any) => {
    const URL = `${this.MEET_BACKEND_URL}/rooms/dial_phone`;
    return this.axiosInstance.post(URL, data);
  };

  exportLogs = async (logs: object) => {
    const URL = `${this.MEET_BACKEND_URL}/rooms/error_logger`;
    return this.axiosInstance.post(URL, logs);
  };

  startInstantMeeting = async (data: any) => {
    const URL = `${this.MEET_BACKEND_URL}/rooms`;
    return this.axiosInstance.post(URL, data);
  };

  connectToPSTN = async (data: any) => {
    const URL = `${this.MEET_BACKEND_URL}/rooms/pstn_access`;
    return this.axiosInstance.post(URL, data);
  };

  scheduleMeeting = async (data: any) => {
    const URL = `${this.MEET_BACKEND_URL}/schedule_meetings`;
    return this.axiosInstance.post(URL, data);
  };

  getScheduleMeetingsData = async (email: string, lookup_window?: number) => {
    const params = { email, lookup_window: lookup_window ?? 30 };
    const URL = `${this.MEET_BACKEND_URL}/schedule_meetings`;
    return this.axiosInstance.get(URL, { params });
  };

  updateScheduleMeeting = async (data: any) => {
    const URL = `${this.MEET_BACKEND_URL}/schedule_meetings/{meeting_uuid}`;
    return this.axiosInstance.patch(URL, data);
  };

  validateGuestUser = async (email: string) => {
    const URL = `${this.MEET_BACKEND_URL}/users/verify_otp`;
    return this.axiosInstance.get(URL, { params: { email } });
  };

  getAccessTokenForGoogleAuthentication = async (code: string) => {
    const URL = `${this.MEET_BACKEND_URL}/users/google_calendar_tokens`;
    return this.axiosInstance.post(URL, { code });
  };

  refreshTokenForGoogleAuthentication = async (data: any) => {
    const URL = `${this.MEET_BACKEND_URL}/users/refresh_token`;
    return this.axiosInstance.post(URL, data);
  };

  verifyGoogleToken = async (email?: string) => {
    const URL = `${this.MEET_BACKEND_URL}/users/google_tokens_validation`;
    return await this.axiosInstance.post(URL, { email });
  };

  // fetchRecordings = async (email: string, page: number, meeting_name?: string | null, date?: string | null) => {
  //   const params = { email, page, meeting_name, date };
  //   const URL = `${this.MEET_BACKEND_URL}/users/recordings`;
  //   return this.axiosInstance.get(URL, { params });
  // };

  updateRecordingRule = async (roomSID: string, call_type: string) => {
    const URL = `${this.MEET_BACKEND_URL}/rooms/${roomSID}/pause_recording`;
    return this.axiosInstance.patch(URL, { call_type });
  };

  getScheduleMeetingDataFromID = async (id: string, email: string, occurrence_id: string | null = null) => {
    //For schedule meeeting we need to paas UUID
    const params = occurrence_id ? { email, occurrence_id } : { email };
    const URL = `${this.MEET_BACKEND_URL}/schedule_meetings/${id}`;

    return this.axiosInstance.get(URL, { params });
  };

  getRecurringMeetingDataFromID = async (id: string, email: string) => {
    //For recurring meeeting we need to paas ID
    const params = { email };
    const URL = `${this.MEET_BACKEND_URL}/recurring_meetings/${id}`;
    return this.axiosInstance.get(URL, { params });
  };

  fetchUserPreference = async (email: string) => {
    const params = { email };
    const URL = `${this.MEET_BACKEND_URL}/users/profile`;
    return this.axiosInstance.get(URL, { params });
  };

  syncAaaUser = async (email: string, name: string) => {
    const params = { email, name };
    const URL = `${this.MEET_BACKEND_URL}/users/`;
    return this.axiosInstance.post(URL, { params });
  };

  updategCalSyncPreference = async (email: string, gcal_down_sync: boolean) => {
    const data = {
      email,
      gcal_down_sync,
    };
    const URL = `${this.MEET_BACKEND_URL}/users/update`;
    return await this.axiosInstance.patch(URL, data);
  };

  submitFeedback = async (data: any) => {
    const URL = `${this.MEET_BACKEND_URL}/feedbacks`;
    return this.axiosInstance.post(URL, data);
  };

  fetchBuilderUsers = async () => {
    const URL = `${this.MEET_BACKEND_URL}/attendees`;
    return this.axiosInstance.get(URL);
  };

  fetchDelegates = async (email: string) => {
    const params = { email };
    const URL = `${this.MEET_BACKEND_URL}/delegates`;
    return this.axiosInstance.get(URL, { params });
  };

  createDelegate = async (data: any) => {
    const URL = `${this.MEET_BACKEND_URL}/delegates`;
    return this.axiosInstance.post(URL, data);
  };

  removeDelegate = async (email: string) => {
    const params = { email };
    const URL = `${this.MEET_BACKEND_URL}/delegates`;
    return this.axiosInstance.delete(URL, { params });
  };

  fetchOwners = async (email: string) => {
    const params = { email };
    const URL = `${this.MEET_BACKEND_URL}/delegates/owners`;
    return this.axiosInstance.get(URL, { params });
  };

  fetchDelegateUsers = async (email: string) => {
    const params = { email };
    const URL = `${this.MEET_BACKEND_URL}/delegates/users`;
    return this.axiosInstance.get(URL, { params });
  };

  fetchDelegateMeetings = async (email: string, lookup_window?: number) => {
    const params = { email, lookup_window };
    const URL = `${this.MEET_BACKEND_URL}/delegates/meetings`;
    return this.axiosInstance.get(URL, { params });
  };

  fetchFeatureFlags = async () => {
    const URL = `${this.MEET_BACKEND_URL}/flipper/api/features`;
    return this.axiosInstance.get(URL);
  };

  createNewFeatureFlag = async (flagData: FeatureToggle) => {
    const URL = `${this.MEET_BACKEND_URL}/flipper/api/features`;
    return this.axiosInstance.post(URL, flagData);
  };

  submitPreflightReport = async (data: any) => {
    const URL = `${this.MEET_BACKEND_URL}/metrics/twilio/pre-flights`;
    return this.axiosInstance.post(URL, data);
  };

  requestWhiteboardAccess = async (room_sid: string, email: string) => {
    const URL = `${this.MEET_BACKEND_URL}/meetings/${room_sid}/whiteboards?email=${email}`;
    const data = {
      bmeet_id: room_sid,
      email: email,
    };
    return await this.axiosInstance.put(URL, data);
  };

  createWhiteboardService = async (meetingID: string, email?: string) => {
    const URL = `${this.MEET_BACKEND_URL}/meetings/${meetingID}/whiteboards`;
    return await this.axiosInstance.post(URL, {
      headers: { Authorization: `Bearer ${email}` },
    });
  };

  updateWhiteboardAccess = async (meetingID: string, whiteboardID: string, email: string, isBuilderUser: boolean) => {
    const data = {
      bmeet_id: meetingID,
      wb_unique_code: whiteboardID,
      invitees: [
        {
          email: email,
          permission: isBuilderUser ? 'editAndDelete' : 'comment',
        },
      ],
    };
    const URL = `${this.MEET_BACKEND_URL}/schedule_meetings/${meetingID}/whiteboards/${whiteboardID}`;
    return await this.axiosInstance.patch(URL, data);
  };
}

export const {
  sendEmail,
  getToken,
  getParticipantList,
  isValidMeetingLink,
  isValidMeetingTime,
  endMeeting,
  dial_phone,
  exportLogs,
  startInstantMeeting,
  connectToPSTN,
  scheduleMeeting,
  getScheduleMeetingsData,
  updateScheduleMeeting,
  validateGuestUser,
  verifyGoogleToken,
  getAccessTokenForGoogleAuthentication,
  refreshTokenForGoogleAuthentication,
  // fetchRecordings,
  updateRecordingRule,
  getScheduleMeetingDataFromID,
  getRecurringMeetingDataFromID,
  fetchUserPreference,
  syncAaaUser,
  updategCalSyncPreference,
  submitFeedback,
  fetchBuilderUsers,
  fetchDelegates,
  createDelegate,
  removeDelegate,
  fetchOwners,
  fetchDelegateUsers,
  fetchDelegateMeetings,
  fetchFeatureFlags,
  createNewFeatureFlag,
  submitPreflightReport,
  requestWhiteboardAccess,
  createWhiteboardService,
  updateWhiteboardAccess,
} = new MeetAPIServiceV1();
