import React, { useState, useRef, useEffect, useCallback, ChangeEvent, MouseEvent, PropsWithChildren } from 'react';
import DialogContent from '@mui/material/DialogContent';
import { DialogTitle, Dialog, Chip, Box, InputBase, Popper, List, ListItem } from '@mui/material';
import classes from './DelegationDialog.module.scss';
import { useAppState } from 'state';
import CloseIcon from '@mui/icons-material/Close';
import CustomButton from 'shared/CustomButton/CustomButton';
import DelegatorList from 'components/DelegatorList/DelegatorList';
import useDeviceType from 'hooks/useDeviceType/useDeviceType';
import { validateEmail } from 'utils';
import { createDelegate, fetchDelegateUsers, fetchDelegates } from 'service/MeetAPIServiceV2';

export interface IDelegate {
  id: number;
  email: string;
  name: string;
}

const isValidEmailDomain = (email: string): boolean => {
  const validDomains: string[] = ['builder.ai', 'x.builder.ai'];
  const domain: string = email.split('@')[1]; // Extract the domain from the email

  if (validDomains.includes(domain)) {
    return true;
  } else {
    return false;
  }
};

interface DelegationDialogProps {
  onClose(): void;
  open: boolean;
}

export default function DelegationDialog({ onClose, open }: PropsWithChildren<DelegationDialogProps>) {
  const { isDarkTheme, user, setAlert } = useAppState();
  const { isMobile } = useDeviceType();
  const [delegatesFullList, setDelegatesFullList] = useState<IDelegate[]>([]);
  const [builderUserEmails, setBuilderUserEmails] = useState<string[]>([]);
  const [emailSuggestions, setEmailSuggestions] = useState<string[]>([]);
  const [emails, setEmails] = useState<string[]>([]);
  const [currentEmail, setCurrentEmail] = useState<string>('');
  const [errorEmails, setErrorEmails] = useState<string[]>([]);
  const [invalidEmails, setInvalidEmails] = useState<string[]>([]);
  const [notBuilderEmails, setNotBuilderEmails] = useState<string[]>([]);
  const [delagtorListName, setDelagtorListName] = useState<IDelegate>();
  const [isRemoveClicked, setIsRemoveClicked] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (open) {
      getDelegate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const getDelegate = () => {
    fetchDelegates(user?.email ?? '')
      .then((res) => {
        setDelagtorListName(res.data.users[0]);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const fetchEmails = useCallback(() => {
    fetchDelegateUsers(user?.email ?? '')
      .then((res: any) => {
        const users = res.data.users;
        setDelegatesFullList(users);
        const emailList = users.map((user: any) => user.email);
        setBuilderUserEmails(emailList);
      })
      .catch((err) => {
        console.log('fetch builder user error', err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isRemoveClicked]);

  useEffect(() => {
    if (open) {
      fetchEmails();
    }
  }, [fetchEmails, open]);

  useEffect(() => {
    // focus on the input field each time emails change
    inputRef.current && inputRef.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emails]);

  const handleDelete = (chipToDelete: string) => () => {
    setEmails((emails) => emails.filter((email) => email !== chipToDelete));
    setErrorEmails((errorEmails) => errorEmails.filter((email) => email !== chipToDelete));
    setNotBuilderEmails((emails) => emails.filter((email) => email !== chipToDelete));
    setInvalidEmails((errorEmails) => errorEmails.filter((email) => email !== chipToDelete));
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setCurrentEmail(value.trim());
    if (value.length >= 3) {
      setEmailSuggestions(builderUserEmails.filter((email) => email.includes(value.toLowerCase())));
    } else {
      setEmailSuggestions([]);
    }
  };

  const handleEmailSelect = (email: string) => {
    setEmails((prevEmails) => [...prevEmails, email]);
    if (!validateEmail(email)) {
      setErrorEmails((prevErrorEmails) => [...prevErrorEmails, email]);
    } else if (!isValidEmailDomain(email)) {
      setNotBuilderEmails((prevErrorEmails) => [...prevErrorEmails, email]);
    } else if (!builderUserEmails.includes(email)) {
      setInvalidEmails((prevErrorEmails) => [...prevErrorEmails, email]);
    }
    setCurrentEmail('');
    setEmailSuggestions([]);
  };

  const handleSuggestionClick = (event: MouseEvent<HTMLLIElement>) => {
    const suggestion = event.currentTarget.textContent;
    if (suggestion) {
      handleEmailSelect(suggestion);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Tab' || event.key === 'Enter') {
      event.preventDefault();
      const suggestion = emailSuggestions[0];
      if (suggestion) {
        handleEmailSelect(suggestion);
      } else if (currentEmail !== '') {
        handleEmailSelect(currentEmail);
      }
    }
  };

  const addDelegate = () => {
    const id = delegatesFullList.find((x) => x.email === emails[0])?.id;
    const data = {
      delegate_id: id,
      email: user?.email,
    };
    createDelegate(data)
      .then((res) => {
        setAlert({
          message: 'Delgate added successfully',
          variant: 'success',
        });
        setEmails([]);
        setDelagtorListName(res.data.users[0]);
      })
      .catch((err) => {
        setAlert({
          message: 'Error while adding delgate',
          variant: 'error',
        });
      });
  };

  const onCloseClick = () => {
    setCurrentEmail('');
    setEmails([]);
    setErrorEmails([]);
    setNotBuilderEmails([]);
    setInvalidEmails([]);
    onClose();
  };

  return (
    <>
      <Dialog
        disableEscapeKeyDown={true}
        open={open}
        onClose={(_: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
          reason !== 'backdropClick' && onCloseClick();
        }}
        fullWidth={true}
        maxWidth="xs"
        PaperProps={{ className: classes.paper }}
        id={isDarkTheme ? 'darkTheme' : 'lightTheme'}
      >
        <DialogTitle style={{ padding: '16px 24px 5px 24px' }}>
          <div className={classes.dialogTitle}>
            <div style={{ visibility: 'collapse' }}></div>
            <div className={classes.text}>{!isMobile && <span className={classes.title}>Add Delegates</span>}</div>
            <div className={classes.close} onClick={onCloseClick}>
              <CloseIcon sx={{ fontSize: '15px' }} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div style={{ marginTop: '1em' }}>
            <div className={classes.emailLabel}>You can add up to one delegate</div>
            <div>
              <Box className={classes.box}>
                <Box display="flex" alignItems="center" flexWrap="wrap">
                  {emails.map((email) => (
                    <Chip
                      key={email}
                      label={email}
                      onDelete={handleDelete(email)}
                      className={`${classes.chip} ${
                        (errorEmails.includes(email) || notBuilderEmails.includes(email)) && classes.errorChip
                      }`}
                      size="small"
                      deleteIcon={
                        <CloseIcon className={classes.closeButton} style={{ color: '#FFF', fontSize: '12px' }} />
                      }
                    />
                  ))}
                  <InputBase
                    sx={{
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'white',
                      },
                    }}
                    placeholder={emails.length === 1 ? '' : 'Enter Email'}
                    value={currentEmail}
                    onChange={handleOnChange}
                    inputRef={inputRef}
                    className={classes.inputBase}
                    onKeyDown={handleKeyDown} // Add the onKeyDown event handler
                    disabled={emails.length === 1 || delagtorListName ? true : false}
                  />
                </Box>
                <Popper
                  placement="bottom-start"
                  open={!!emailSuggestions.length}
                  anchorEl={inputRef.current}
                  className={classes.popper}
                >
                  <List>
                    {emailSuggestions.map((suggestion) => (
                      <ListItem key={suggestion} onClick={handleSuggestionClick} className={classes.listItem}>
                        {suggestion}
                      </ListItem>
                    ))}
                  </List>
                </Popper>
              </Box>
              {notBuilderEmails.length > 0 && (
                <p className={classes.errorText}>{'*Delegate should be a Builder.ai employee'}</p>
              )}
              {errorEmails.length > 0 && <p className={classes.errorText}>{'*Please enter a valid email'}</p>}
              {invalidEmails.length > 0 && (
                <p className={classes.errorText}>{'*You cannot add this user as a delegate'}</p>
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1em' }}>
              <CustomButton
                isDisabled={
                  emails.length === 0 ||
                  notBuilderEmails.length > 0 ||
                  errorEmails.length > 0 ||
                  invalidEmails.length > 0
                }
                buttonName="Add Delegate"
                onClick={addDelegate}
                btnClass={classes.delegateBtn}
              />
            </div>
          </div>

          {delagtorListName && (
            <DelegatorList
              delagtorListName={delagtorListName}
              setDelagtorListName={setDelagtorListName}
              setIsRemoveClicked={setIsRemoveClicked}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
