import React, { useState } from 'react';
import classes from './RecordingAttachments.module.scss';
import useCopyLink from 'hooks/useCopyLink/useCopyLink';
import { IRecordingsData, IError } from 'types';
import { useAppState } from 'state';
import VerticalDotsIcon from 'icons/VerticalDotsIcon';
import { Menu, MenuItem } from '@mui/material';
import OpenReordingIcon from 'icons/OpenReordingIcon';
import RecordingWhiteboardIcon from 'icons/RecordingWhiteboardIcon';
import { useRecordingStore } from 'store/recordingStore';
import useDeviceType from 'hooks/useDeviceType/useDeviceType';
import { getRecording } from 'service/MeetAPIServiceV2';
import { Download } from 'lucide-react';
import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import { useNavigate } from 'react-router-dom';

interface RecordingAttachmentsProps {
  recordingData: IRecordingsData;
}

function RecordingAttachments({ recordingData }: RecordingAttachmentsProps) {
  const { isMobile } = useDeviceType();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [error, setError] = useState<IError | null>(null);
  const { setRecordingMetaData } = useRecordingStore((state) => ({
    setRecordingMetaData: state.setRecordingMetaData,
  }));

  const { user } = useAppState();
  const navigate = useNavigate();

  const onWhiteBoardClick = async (isRecordingClick: boolean) => {
    let recordingLink = recordingData.media_uri;

    if (isRecordingClick) {
      const response = await getRecording(null, recordingData.recording_sid);
      recordingLink = response.data.data.attributes.url;
    }
    navigate(`/recordings/${recordingData.recording_sid}`, { state: { from: 'recordings' } });

    setRecordingMetaData({
      name: recordingData.name || `${user?.name}'s meeting`,
      time: recordingData.start_date,
      duration: recordingData.duration,
      whiteboard_link: `${recordingData.whiteboard_url}?email=${user?.email}&url=${window.location.href}`,
      recording_link: recordingLink,
      isRecordingClick: isRecordingClick,
    });
  };

  const handleDownload = async () => {
    try {
      const response = await getRecording(null, recordingData.recording_sid);
      const downloadUrl = response.data.data.attributes.url;
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = downloadUrl;
      link.target = '_blank';
      link.download = `${recordingData.name || 'recording'}.mp4`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading recording:', error);
      setError({
        error: {
          name: 'Download Failed',
          message: 'Failed to download the recording. Please try again later.',
          code: 0,
        },
        type: 'download',
      });
    }
  };

  const dismissError = () => {
    setError(null);
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        onClick={() => {
          setAnchorEl(null);
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        MenuListProps={{ className: classes.menuList }}
        PaperProps={{ style: { marginLeft: '15px' } }}
      >
        <MenuItem onClick={handleDownload}>
          <span className={classes.icons}>
            <Download />
          </span>
          Download
        </MenuItem>
        <MenuItem
          onClick={() => {
            onWhiteBoardClick(true);
          }}
        >
          <span className={classes.icons}>
            {' '}
            <OpenReordingIcon />
          </span>
          Open Recording
        </MenuItem>
        {!isMobile && recordingData.whiteboard_url && (
          <MenuItem
            onClick={() => {
              onWhiteBoardClick(false);
            }}
          >
            <span className={classes.icons} style={{ marginLeft: '0.1em' }}>
              <RecordingWhiteboardIcon />
            </span>
            Open Whiteboard
          </MenuItem>
        )}
      </Menu>

      <div
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget);
        }}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <span className={classes.threeDota}>
          {' '}
          <VerticalDotsIcon isRecordingUI />
        </span>
      </div>

      <ErrorDialog dismissError={dismissError} error={error} />
    </>
  );
}

export default RecordingAttachments;
