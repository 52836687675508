import React, { useEffect, useState } from 'react';
import { Table, TableCell, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { convertStringIntoDate } from 'utils/constants';
import { useAppState } from 'state';
import { IRecordingsData } from 'types';
import CustomButton from 'shared/CustomButton/CustomButton';
import moment from 'moment';
import RecordingDataTable from './RecordingDataTable';
import RecordingSearchContainer from './RecordingSearchContainer';
import classes from './RecordingLinksComponent.module.scss';
import RecordingWhiteboard from './RecordingMetaDataView';
import LeftArrow from 'icons/LeftArrow';
import { useRecordingStore } from 'store/recordingStore';
import { fetchRecordings } from 'service/MeetAPIServiceV2';

export default function RecordingLinksComponent() {
  const [recordingsData, setRecordingsData] = useState<IRecordingsData[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [searchDate, setSearchDate] = useState<Date | null>(null);

  const { user, setAlert } = useAppState();
  const { recordingMetaData, setRecordingMetaData } = useRecordingStore((state) => ({
    recordingMetaData: state.recordingMetaData,
    setRecordingMetaData: state.setRecordingMetaData,
  }));

  useEffect(() => {
    if (user) {
      getRecordings(user.email, 1, null, null); //On Initial Fetch call search value would be null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (!searchValue && !searchDate) {
      if (user) {
        getRecordings(user.email, 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, searchDate, user]);

  const showMore = () => {
    setCurrentPage(currentPage + 1);
    const date = searchDate && moment(searchDate).format('DD-MM-yyyy');
    if (user) {
      getRecordings(user.email, currentPage + 1, searchValue, date);
    }
  };

  const getRecordings = (email: string, page: number, meetingName?: string | null, date?: string | null) => {
    // setIsFetchingRecording(true);
    fetchRecordings(page, meetingName, date)
      .then((res) => {
        const fetchedData = res.data.recordings as IRecordingsData[];
        const allRecordingsData = page === 1 ? fetchedData : [...recordingsData, ...fetchedData];
        allRecordingsData.forEach((data) => {
          data.start_date = convertStringIntoDate(data.scheduled_time ? data.scheduled_time : data.actual_time);
        });
        setRecordingsData(allRecordingsData);
        setTotalPages(res.data.meta.total_pages);
      })
      .catch(() => {
        setAlert({
          message: 'Error while getting recordings data',
          variant: 'error',
          isTwilioError: true,
        });
      })
      .finally(() => {
        // setIsFetchingRecording(false);
      });
  };

  return (
    <div className={`${classes.main}`}>
      <span style={{ padding: '1em 0em 1em 0em', fontSize: '20px', fontWeight: 'bold', display: 'flex' }}>
        {recordingMetaData && (
          <span
            onClick={() => {
              setRecordingMetaData(null);
            }}
            style={{ cursor: 'pointer', marginRight: '2em', display: 'flex', alignItems: 'center' }}
          >
            <LeftArrow />
          </span>
        )}
        Meetings
      </span>
      <>
        {recordingMetaData ? (
          <RecordingWhiteboard />
        ) : (
          <>
            <RecordingSearchContainer
              getRecordings={getRecordings}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              searchDate={searchDate}
              setSearchDate={setSearchDate}
              setCurrentPage={setCurrentPage}
              setRecordingsData={setRecordingsData}
            />

            <TableContainer>
              <Table stickyHeader aria-label="recording table">
                <TableHead>
                  <TableRow>
                    <TableCell className={`${classes.head} ${classes.headFont}`} align="center">
                      {/* ID */}
                    </TableCell>
                    <TableCell className={`${classes.head} ${classes.headFont}`} align="center">
                      Time
                    </TableCell>
                    <TableCell className={`${classes.head} ${classes.headFont}`} align="center">
                      Name
                    </TableCell>
                    <TableCell className={`${classes.head} ${classes.headFont}`} align="center">
                      <div style={{ display: 'flex', justifyContent: 'center' }}>Duration</div>
                    </TableCell>
                    <TableCell className={`${classes.head} ${classes.headFont}`} align="center">
                      Size
                    </TableCell>
                    <TableCell className={`${classes.head} ${classes.headFont}`} align="center">
                      Attachments
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {recordingsData.length > 0 &&
                    recordingsData.map((row, index) => {
                      return <RecordingDataTable recordingData={row} key={row.id} index={index} />;
                    })}
                </TableBody>
              </Table>
            </TableContainer>

            {recordingsData.length !== 0 ? (
              <div className={classes.showMore}>
                <CustomButton
                  btnClass={classes.btnClass}
                  isDisabled={currentPage === totalPages}
                  buttonName="Show More"
                  onClick={showMore}
                />
              </div>
            ) : (
              <span className={classes.records}>No Records Found</span>
            )}
          </>
        )}
      </>
    </div>
  );
}
