import BuilderGIFComponent from 'components/PreJoinComponents/BuilderGIFComponent/BuilderGIFComponent';
import RecordingMetaDataView from 'components/PreJoinComponents/RecordingLinksComponent/RecordingMetaDataView';
import LeftArrow from 'icons/LeftArrow';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getRecording } from 'service/MeetAPIServiceV2';
import { useAppState } from 'state';
import { useRecordingStore } from 'store/recordingStore';

import styles from './ViewRecordingComponent.module.scss';

const ViewRecordingComponent = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { setAlert } = useAppState();
  const setRecordingMetaData = useRecordingStore((state) => state.setRecordingMetaData);
  const { user } = useAppState();

  useEffect(() => {
    const recordingId = id!.replace(/\.[^.]+/, '');
    getRecording(null, recordingId)
      .then((recording) => {
        const recordingData = recording.data.data.attributes;
        setRecordingMetaData({
          name: recordingData.name,
          time: recordingData.time,
          duration: recordingData.duration,
          whiteboard_link: `${recordingData.whiteboard_link}?email=${user?.email}&url=${window.location.href}`,
          recording_link: recordingData.url,
          isRecordingClick: true,
        });

        setIsLoading(false);
      })
      .catch((error) => {
        const response = error.response.data;
        const errors = response.errors;

        if (errors?.length) {
          setAlert({ variant: 'error', message: errors[0].detail });
        } else {
          setAlert({ variant: 'error', message: 'An unexpected error occurred' });
        }
      });
  }, []);

  const { state } = useLocation();
  const navigate = useNavigate();
  console.log(state);

  if (isLoading) {
    return <BuilderGIFComponent heigth={20} width={10} />;
  }

  return (
    <div className={styles.main}>
      {state?.from === 'recordings' && (
        <span style={{ padding: '1em 0em 1em 0em', fontSize: '20px', fontWeight: 'bold', display: 'flex' }}>
          <span
            onClick={() => navigate(-1)}
            style={{ cursor: 'pointer', marginRight: '2em', display: 'flex', alignItems: 'center' }}
          >
            <LeftArrow />
          </span>
          Meetings
        </span>
      )}
      <RecordingMetaDataView />
    </div>
  );
};

export default ViewRecordingComponent;
