'use client';

import { useEffect, useRef } from 'react';
import { useAuth } from 'react-oidc-context';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import useSetUserFromToken from 'hooks/useGoogle/useSetUserFromToken';
import { useAuthActions } from 'hooks/useAuthActions/useAuthActions';
import React from 'react';

export default function PrivateRoute() {
  const auth = useAuth();
  const setUserFromToken = useSetUserFromToken();
  const { authSignIn } = useAuthActions();
  const location = useLocation();
  const navigate = useNavigate();
  const hasRedirected = useRef(false);

  useEffect(() => {
    let isMounted = true;

    const handleAuth = async () => {
      if (auth.isAuthenticated && isMounted) {
        await setUserFromToken();
        const storedPath = sessionStorage.getItem('intendedPath');
        if (storedPath && !hasRedirected.current) {
          hasRedirected.current = true;
          navigate(storedPath, { replace: true });
          sessionStorage.removeItem('intendedPath');
        }
      }
    };

    handleAuth();

    return () => {
      isMounted = false;
    };
  }, [auth.isAuthenticated, setUserFromToken, navigate]);

  useEffect(() => {
    if (!auth.isAuthenticated && !auth.isLoading) {
      sessionStorage.setItem('intendedPath', location.pathname + location.search);
      authSignIn();
    }
  }, [auth.isAuthenticated, auth.isLoading, authSignIn, location]);

  if (auth.isLoading) {
    return null;
  }

  return auth.isAuthenticated ? <Outlet /> : null;
}
