'use client';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppState } from 'state';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { VideoProvider } from 'components/VideoProvider';
import { ChatProvider } from 'components/ChatProvider';
import UnsupportedBrowserWarning from 'components/PreJoinComponents/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import BuilderMeetRoutes from 'Routes/Routes'; //This import was likely the issue.  Consider refactoring to a more robust solution.
import darkTheme from 'themes/darkTheme';
import lightTheme from 'themes/lightTheme';
import useConnectionOptions from 'hooks/useConnectionOptions/useConnectionOptions';
import './App.scss';
import useSetUserFromToken from 'hooks/useGoogle/useSetUserFromToken';
import { useAuth } from 'react-oidc-context';
import useFeatureFlags from 'hooks/useFeatureFlags/useFeatureFlags';
import { useFaro } from 'hooks/useFaro/useFaro';
import React from 'react';

export default function App() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { error, setError, isDarkTheme } = useAppState();
  const connectionOptions = useConnectionOptions();
  const setUserFromToken = useSetUserFromToken();
  const { featureFlagsCallback } = useFeatureFlags();
  const initFaroCallback = useFaro();

  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  // useEffect(() => {
  //   initFaroCallback();
  // });

  useEffect(() => {
    // This is used to set attribute for Dark Theme for scss
    document.documentElement.setAttribute('data-theme', isDarkTheme ? 'dark' : 'light');
  }, [isDarkTheme]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      setUserFromToken();
      const intendedPath = sessionStorage.getItem('intendedPath');
      if (intendedPath) {
        sessionStorage.removeItem('intendedPath');
        navigate(intendedPath);
      }
    } else if (!auth.isLoading && !hasTriedSignin) {
      const signedInEvent = localStorage.getItem('signedInEvent');
      if (signedInEvent === 'true') {
        auth.signinSilent();
        setHasTriedSignin(true);
      }
    }
  }, [auth.isAuthenticated, auth.isLoading, hasTriedSignin, setUserFromToken, auth, navigate]);

  useEffect(() => {
    featureFlagsCallback();
  }, []);

  return (
    <div id={isDarkTheme ? 'darkTheme' : 'lightTheme'}>
      <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
        <CssBaseline />
        <UnsupportedBrowserWarning>
          <VideoProvider options={connectionOptions} onError={(err, type) => setError({ error: err, type: type })}>
            <ChatProvider>
              <ErrorDialog dismissError={() => setError(null)} error={error} />
              <BuilderMeetRoutes />
            </ChatProvider>
          </VideoProvider>
        </UnsupportedBrowserWarning>
      </ThemeProvider>
    </div>
  );
}
