import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { scheduleMeeting as scheduleMeetingV2, updateScheduleMeeting } from 'service/MeetAPIServiceV2';
import { useAppState } from 'state';

interface UseScheduleMeetingAPI {
  createScheduleMeeting: (data: any) => void;
  updateMeeting: (data: any) => void;
  btnDisabled: boolean;
}

const useScheduleMeetingAPI = (): UseScheduleMeetingAPI => {
  const { setAlert } = useAppState();
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false);
  const navigate = useNavigate();

  const createScheduleMeeting = (data: any) => {
    setBtnDisabled(true);
    scheduleMeetingV2(data)
      .then(() => {
        setAlert({
          variant: 'success',
          message: 'Meeting has been scheduled successfully',
        });
        navigate(-1);
      })
      .catch((err) => {
        const code = err.response.status;
        const message = err.response.data.message;

        if (code === 422 && message === 'Google sign-in required with calendar permissions') {
          setAlert({
            variant: 'error',
            message: 'Google sign-in required with calendar permissions',
          });
        } else {
          setAlert({
            variant: 'error',
            message: 'Error while scheduling meeting',
          });
        }
        console.log(err);
      })
      .finally(() => {
        setBtnDisabled(false);
      });
  };

  const updateMeeting = (data: any) => {
    setBtnDisabled(true);
    updateScheduleMeeting(data)
      .then((res) => {
        setAlert({
          variant: 'success',
          message: 'Meeting has been updated successfully',
        });
        navigate(-1);
      })
      .catch((err) => {
        setAlert({
          variant: 'error',
          message: 'Error while updating scheduling meeting',
        });
        console.log('Update Meeting Error', err);
      })
      .finally(() => {
        setBtnDisabled(false);
      });
  };

  return { createScheduleMeeting, updateMeeting, btnDisabled };
};

export default useScheduleMeetingAPI;
