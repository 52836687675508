import { AxiosRequestHeaders } from 'axios';
import MeetAPIServiceBase from './MeetAPIServiceBase';

export default class MeetAPIServiceV2 extends MeetAPIServiceBase {
  API_VERSION: string = 'v2';
  MEET_BACKEND_URL: string = `${process.env.REACT_APP_MEET_BACKEND_URL}/${this.API_VERSION}`;

  axiosHeader = {
    'Content-Type': 'application/vnd.api+json',
  };

  private requestHeaders(email: string | null | undefined): AxiosRequestHeaders {
    return {
      Authorization: `Bearer ${this.apiToken(email)}`,
      ...this.axiosHeader,
    };
  }

  public createCalendarProvider = async (
    email: string | null | undefined,
    authorization_code: string,
    provider: string
  ) => {
    const URL = `${this.MEET_BACKEND_URL}/calendar_providers`;
    const data = {
      data: {
        type: 'calendar_providers',
        attributes: {
          provider,
          authorization_code,
        },
      },
    };
    return this.axiosInstance.post(URL, data, { headers: this.requestHeaders(email) });
  };

  public fetchRecordings = async (page: number, meeting_name?: string | null, date?: string | null) => {
    const params = { page, meeting_name, date };
    const URL = `${this.MEET_BACKEND_URL}/recordings`;
    return this.axiosInstance.get(URL, { params, headers: this.requestHeaders(undefined) });
  };

  public fetchUserPreferences = async (email: string | null | undefined) => {
    const URL = `${this.MEET_BACKEND_URL}/user`;
    return this.axiosInstance.get(URL, { headers: this.requestHeaders(email) });
  };

  public saveUserPreferences = async (email: string | null | undefined, userPreferences: any) => {
    const URL = `${this.MEET_BACKEND_URL}/user`;
    return this.axiosInstance.put(URL, userPreferences, { headers: this.requestHeaders(email) });
  };

  public getUserUploadedBackgroundImage = async (email: string | null | undefined) => {
    const URL = `${this.MEET_BACKEND_URL}/background_images`;
    return this.axiosInstance.get(URL, { headers: this.requestHeaders(email) });
  };

  public setUserUploadedBackgroundImage = async (data: any, email: string | null | undefined) => {
    const URL = `${this.MEET_BACKEND_URL}/background_images`;
    return this.axiosInstance.post(URL, data, { headers: this.requestHeaders(email) });
  };

  public getRecording = async (email: string | null | undefined, recordingId: string) => {
    const URL = `${this.MEET_BACKEND_URL}/recordings/${recordingId}`;
    return this.axiosInstance.get(URL, { headers: this.requestHeaders(email) });
  };

  public scheduleMeeting = async (data: any) => {
    const URL = `${this.MEET_BACKEND_URL}/schedule_meetings`;
    return this.axiosInstance.post(URL, data, { headers: this.requestHeaders(undefined) });
  };

  public updateScheduleMeeting = async (data: any) => {
    const URL = `${this.MEET_BACKEND_URL}/schedule_meetings/{meeting_uuid}`;
    return this.axiosInstance.patch(URL, data, { headers: this.requestHeaders(undefined) });
  };

  public getScheduleMeetingsData = async (email: string, lookup_window?: number) => {
    const params = { email, lookup_window: lookup_window ?? 30 };
    const URL = `${this.MEET_BACKEND_URL}/schedule_meetings`;
    return this.axiosInstance.get(URL, { params, headers: this.requestHeaders(undefined) });
  };

  public fetchDelegates = async (email: string) => {
    const params = { email };
    const URL = `${this.MEET_BACKEND_URL}/delegates`;
    return this.axiosInstance.get(URL, { params, headers: this.requestHeaders(undefined) });
  };

  public createDelegate = async (data: any) => {
    const URL = `${this.MEET_BACKEND_URL}/delegates`;
    return this.axiosInstance.post(URL, data, { headers: this.requestHeaders(undefined) });
  };

  public removeDelegate = async (email: string) => {
    const params = { email };
    const URL = `${this.MEET_BACKEND_URL}/delegates`;
    return this.axiosInstance.delete(URL, { params, headers: this.requestHeaders(undefined) });
  };

  public fetchOwners = async (email: string) => {
    const params = { email };
    const URL = `${this.MEET_BACKEND_URL}/delegates/owners`;
    return this.axiosInstance.get(URL, { params, headers: this.requestHeaders(undefined) });
  };

  public fetchDelegateUsers = async (email: string) => {
    const params = { email };
    const URL = `${this.MEET_BACKEND_URL}/delegates/users`;
    return this.axiosInstance.get(URL, { params, headers: this.requestHeaders(undefined) });
  };

  public fetchDelegateMeetings = async (email: string, lookup_window?: number) => {
    const params = { email, lookup_window };
    const URL = `${this.MEET_BACKEND_URL}/delegates/meetings`;
    return this.axiosInstance.get(URL, { params, headers: this.requestHeaders(undefined) });
  };
}

export const {
  createCalendarProvider,
  fetchRecordings,
  fetchUserPreferences,
  saveUserPreferences,
  getUserUploadedBackgroundImage,
  setUserUploadedBackgroundImage,
  getRecording,
  scheduleMeeting,
  updateScheduleMeeting,
  getScheduleMeetingsData,
  fetchDelegates,
  createDelegate,
  removeDelegate,
  fetchOwners,
  fetchDelegateUsers,
  fetchDelegateMeetings,
} = new MeetAPIServiceV2();
