import { useCallback } from 'react';
import { useAppState } from 'state';
import { useAuth } from 'react-oidc-context';
import { isBuilderAIUser } from 'utils';
import { capitalizedString } from 'utils/constants';
import { syncAaaUser } from 'service/MeetAPIServiceV1';
import { fetchUserPreferences } from 'service/MeetAPIServiceV2';

const useSetUserFromToken = () => {
  const { setIsBuilderUser, setUser, setUserName, setGuestEmail } = useAppState();
  const auth = useAuth();

  const setUserFromToken = useCallback(() => {
    const userProfile = auth?.user?.profile;
    const email = userProfile?.email ?? '';
    const name = userProfile?.name ?? email;
    const profilePic = window.localStorage.getItem('profilePicture');

    fetchUserPreferences(undefined)
      .then((res) => {
        const user = res.data.data.attributes;
        const userID = res.data.data.id;
        const userName = user?.name ?? name;
        setUserName(capitalizedString(userName));
        setUser({ email: email, name: capitalizedString(userName), profile_picture: profilePic ?? null, id: userID });
      })
      .catch((err) => {
        syncAaaUser(email, name).catch((err) => {
          console.log(err);
        });
        console.log(err);
        setUserName(capitalizedString(name));
        setUser({ email: email, name: capitalizedString(name), profile_picture: profilePic ?? null, id: -1 });
      });
    setGuestEmail(email)
    setIsBuilderUser(isBuilderAIUser(email));
    window.localStorage.setItem('user-email', email);
  }, [auth, setGuestEmail, setIsBuilderUser, setUser, setUserName]);

  return setUserFromToken;
};

export default useSetUserFromToken;
