import HomePage from 'components/PreJoinComponents/HomePage/HomePage';
import ScheduleScreenDialog from 'components/PreJoinComponents/ScheduleMeetingDialogComponent/ScheduleMeetingDialogComponent';
import RecordingLinksComponent from 'components/PreJoinComponents/RecordingLinksComponent/RecordingLinksComponent';
import JoinMeetingDialogComponent from 'components/PreJoinComponents/JoinMeetingDialogComponent/JoinMeetingDialogComponent';
import ScheduleMeetingsComponent from 'components/PreJoinComponents/ScheduleMeetingsComponent/ScheduleMeetingsComponent';
import WelcomeScreenComponent from 'components/PreJoinComponents/WelcomeScreenComponent/WelcomeScreenComponent';
import CalendarIcon from 'icons/CalendarIcon';
import HomeIcon from 'icons/HomeIcon';
import PlusIcon from 'icons/PlusIcon';
import RecordingSideBarIcon from 'icons/RecordingSideBarIcon';
import ScheduleIcon from 'icons/ScheduleIcon';
import React from 'react';
import VideoApp from 'components/VideoApp/VideoApp';
import { Route, Routes, Navigate } from 'react-router-dom';
import { activeElement } from 'utils/enums';
import {
  HomeRoute,
  JoinMeetingRoute,
  RecordingRoute,
  ViewRecordingRoute,
  ScheduleMeetingRoute,
  UpcomingMeetingRoute,
  UpdateScheduleMeetingRoute,
  UpdateRecurringMeetingRoute,
  MobileUserProfile,
  FeedbackRoute,
  // UserProfileRoute,
} from 'utils/constants';
import PrivateRoute from './PrivateRoute';
import useDeviceType from 'hooks/useDeviceType/useDeviceType';
import MobileWelcomeScreenComponent from 'MobileComponents/MobileWelcomeScreenComponent/MobileWelcomeScreenComponent';
import MobileUserProfileComponent from 'MobileComponents/MobileUserProfile/MobileUserProfileComponent';
import MobileUpcomingMeetingsComponent from 'MobileComponents/MobileUpcomingMeetingsComponent/MobileUpcomingMeetingsComponent';
import MobileRecordingComponent from 'MobileComponents/MobileRecordingComponent/MobileRecordingComponent';
import SubmitFeedbackComponent from 'components/SubmitFeedbackComponent/SubmitFeedbackComponent';
import MobileScheduleMeetingComponent from 'MobileComponents/MobileScheduleMeetingComponent/MobileScheduleMeetingComponent';
import RoomProvider from 'components/RoomProvider';
import ViewRecordingComponent from 'components/ViewRecordingComponent/ViewRecordingComponent';
// import UserProfileComponent from 'components/UserProfileComponent/UserProfileComponent';
// import SettingsIcon from 'icons/SettingsIcon';

export default function BuilderMeetRoutes() {
  const { isMobile } = useDeviceType();

  return (
    <Routes>
      <Route path="/:room" element={<VideoApp />} />
      <Route
        path="/"
        element={
          <HomePage
            children={isMobile ? <MobileWelcomeScreenComponent /> : <WelcomeScreenComponent />}
            activeTab={activeElement.Home}
            title="Home"
            Icon={<HomeIcon />}
          />
        }
      />
      <Route
        path={HomeRoute}
        element={
          <HomePage
            children={isMobile ? <MobileWelcomeScreenComponent /> : <WelcomeScreenComponent />}
            activeTab={activeElement.Home}
            title="Home"
            Icon={<HomeIcon />}
          />
        }
      />
      <Route
        path={JoinMeetingRoute}
        element={
          <HomePage
            children={<JoinMeetingDialogComponent />}
            activeTab={activeElement.Plus}
            title="Join A Meeting"
            Icon={<PlusIcon />}
          />
        }
      />
      <Route path={UpcomingMeetingRoute} element={<PrivateRoute />}>
        <Route
          path={UpcomingMeetingRoute}
          element={
            <HomePage
              children={isMobile ? <MobileUpcomingMeetingsComponent /> : <ScheduleMeetingsComponent />}
              activeTab={activeElement.Upcoming}
              title="Upcoming Meetings"
              Icon={<ScheduleIcon />}
            />
          }
        />
      </Route>
      <Route path={ScheduleMeetingRoute} element={<PrivateRoute />}>
        <Route
          path={ScheduleMeetingRoute}
          element={
            <HomePage
              children={isMobile ? <MobileScheduleMeetingComponent /> : <ScheduleScreenDialog />}
              activeTab={activeElement.Calender}
              title="Schedule A Meeting"
              Icon={<CalendarIcon />}
            />
          }
        />
      </Route>
      <Route path={UpdateScheduleMeetingRoute} element={<PrivateRoute />}>
        <Route
          path={UpdateScheduleMeetingRoute}
          element={
            <HomePage
              children={isMobile ? <MobileScheduleMeetingComponent /> : <ScheduleScreenDialog />}
              activeTab={activeElement.Calender}
              title="Update A Meeting"
              Icon={<CalendarIcon />}
            />
          }
        />
      </Route>
      <Route path={UpdateRecurringMeetingRoute} element={<PrivateRoute />}>
        <Route
          path={UpdateRecurringMeetingRoute}
          element={
            <HomePage
              children={isMobile ? <MobileScheduleMeetingComponent /> : <ScheduleScreenDialog />}
              activeTab={activeElement.Calender}
              title="Update A Meeting"
              Icon={<CalendarIcon />}
            />
          }
        />
      </Route>
      <Route path={RecordingRoute} element={<PrivateRoute />}>
        <Route
          path={RecordingRoute}
          element={
            <HomePage
              children={isMobile ? <MobileRecordingComponent /> : <RecordingLinksComponent />}
              activeTab={activeElement.Recording}
              title="Recordings"
              Icon={<RecordingSideBarIcon />}
            />
          }
        />
      </Route>
      <Route path={ViewRecordingRoute} element={<PrivateRoute />}>
        <Route
          path={ViewRecordingRoute}
          element={
            <HomePage
              children=<ViewRecordingComponent />
              activeTab={activeElement.Recording}
              title="Recording"
              Icon={<RecordingSideBarIcon />}
            />
          }
        />
      </Route>
      <Route path={MobileUserProfile} element={<PrivateRoute />}>
        <Route
          path={MobileUserProfile}
          element={
            <HomePage
              children={<MobileUserProfileComponent />}
              activeTab={activeElement.MobileProfile}
              title="Profile"
              Icon={<RecordingSideBarIcon />}
            />
          }
        />
      </Route>
      <Route
        path={FeedbackRoute}
        element={
          <HomePage
            children={<SubmitFeedbackComponent />}
            activeTab={activeElement.Feedback}
            title="Submit Feedback"
            Icon={<></>}
          />
        }
      />
      {/* <Route path={UserProfileRoute} element={<PrivateRoute />}>
        <Route
          path={UserProfileRoute}
          element={
            <HomePage
              children={<UserProfileComponent />}
              activeTab={activeElement.UserProfile}
              title="User Profile"
              Icon={<SettingsIcon />}
            />
          }
        />
      </Route>{' '} */}
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
}
